import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import { mapEdgesToNodes } from "../lib/helpers";

const Page = ({ data }) => {
  const teamMembersNodes = (data || {}).teamMembers
    ? mapEdgesToNodes(data.teamMembers)
    : [];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Meet The Team | DeBlanc + Murphy | CPA Firm"
        description="DeBlanc + Murphy experts include Certified Public Accountants, Tax Attorneys, Certified Valuation Analysts, and more. Serving the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-8 md:mb-32 md:pt-14">
        <div className="container">
          <header className="mb-20 max-w-[720px] md:mb-28">
            <h1>Meet the DeBlanc, Murphy & Murphy Team</h1>
            <p className="md:text-xl">
            Our professionals include Certified Public Accountants, Personal Financial Specialists, Certified Financial Planners, Registered Tax Preparers, and other top experts ready to work for you.
            </p>
          </header>

          {teamMembersNodes.length > 0 && (
            <div className="mb-16 grid grid-cols-2 gap-y-6 gap-x-4 md:mb-26 md:grid-cols-3 md:gap-y-12 md:gap-x-6 lg:grid-cols-4">
              {teamMembersNodes.map((item, i) => (
                <div key={i}>
                  <Link
                    to={`/${item.slug.current}/`}
                    className="group font-normal no-underline"
                  >
                    <div className="mb-4 overflow-hidden">
                      <GatsbyImage
                        image={item.headshot.asset.gatsbyImageData}
                        className="z-0 w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div className="heading-five mb-1">{item.name}</div>
                    <div className="mb-0 text-sm uppercase text-typography-body">
                      {item.title}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    teamMembers: allSanityTeamMember(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          name
          slug {
            current
          }
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 374)
            }
          }
          title
        }
      }
    }
  }
`;

export default Page;
